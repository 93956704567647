.nav-global {

  @include respond-to(911px) {
    position: absolute;
    top: -84px;
    left: 271px;
    width: 640px;
    background: $dove-gray;
  }

  .ul1 {
    display: block;
    margin: 0;
    list-style: none;
  }

  .li1 {
    display: block;
    float: left;
    width: 50%;
    border-color: $cedar;
    border-style: solid;
    border-width: 0 0 2px;

    @include respond-to(911px) {
      position: relative;
      display: block;
      float: left;
      width: auto;
      height: 26px;
      background: none;
      border: 0;
    }

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: 9px;
        right: 0;
        display: block;
        width: 1px;
        height: 9px;
        background: $mischka;
        content: '';
      }
    }

    &:last-child::after {

      @include respond-to(911px) {
        display: none;
      }
    }

    &:nth-of-type(odd) {
      border-right-width: 2px;

      @include respond-to(911px) {
        border-right-width: 0;
      }
    }
  }

  .a1 {
    display: block;
    height: 48px;
    padding: 11px 17px 10px;
    font-size: 18px;
    color: $janna;
    text-align: right;
    text-transform: uppercase;
    background: $makara;

    @include respond-to(911px) {
      display: block;
      height: 26px;
      padding: 0 11px 0 9px;
      font-size: 10px;
      line-height: 26px;
      color: $mischka;
      background: none;
    }

    &:hover {

      @include respond-to(911px) {
        color: $white;
      }
    }

    i {
      margin-right: 5px;

      @include respond-to(911px) {
        margin-right: 2px;
      }
    }
  }
}
