.accordions {
  margin: 10px 0;
  border-color: $kabul;
  border-style: solid;
  border-width: 0 1px 1px;

  .accordion {
    background: $bone;
    border-top: 1px solid $kabul;

    &.search-disease {
      background: $soft-amber;
    }

    &.search-drug {
      background: $bison-hide;
    }
  }

  .accordion-heading {
    position: relative;
    display: block;

    .accordion-title {
      position: relative;
      display: block;
      padding: 19px 60px 19px 17px;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: $coffee-bean;
      cursor: pointer;
      user-select: none;
    }

    .accordion-indicator {
      position: absolute;
      top: 20px;
      right: 21px;
      width: 18px;
      height: 15px;
      background: url("../images/generic-arrow.png") no-repeat 0 0;
    }
  }

  .accordion-content {
    display: none;
    padding: 0 17px 17px;
  }

  .accordion.active {

    .accordion-indicator {
      transform: rotate(180deg);
    }

    .accordion-content {
      display: block;
    }
  }
}
