.nav-admin {

  position: relative;
  display: block;

  @include respond-to(911px) {
    position: absolute;
    top: -58px;
    left: 271px;
    display: block;
    width: 640px;
    height: 58px;
  }

  .superadmin-menu {

    .primary {

      @include respond-to(911px) {
        padding-top: 10px;
        border-bottom: 1px solid $coffee;
      }
    }

    .secondary {
      padding-top: 0;

      @include respond-to(911px) {
        padding-top: 5px;
        border-bottom: 1px solid $coffee;
      }
    }
  }

  .ul1 {

    display: block;
    padding: 21px 0 0;
    margin: 0;
    list-style: none;

    @include respond-to(911px) {
      padding: 37px 11px 0;
      margin: 0;
    }
  }

  .li1 {

    display: block;

    @include respond-to(911px) {
      float: left;
      margin: 0 7px 0 0;
    }
  }

  .a1 {
    display: block;
    padding: 4px 17px;
    font-size: 18px;
    font-weight: 400;
    color: $alabaster;
    text-align: right;

    @include respond-to(911px) {
      height: 21px;
      padding: 0 8px 0 6px;
      font-family: $verdana;
      font-size: 10px;
      font-weight: 400;
      line-height: 21px;
      color: $alabaster;
      text-align: center;
      text-transform: uppercase;
      background: $kabul;
      border-radius: 8px 8px 0 0;
    }

    &:hover {

      @include respond-to(911px) {
        color: $white;
        background: $mondo;
      }
    }
  }
}
