.page-about {

  .page-content {

    @include respond-to(911px) {
      background-image: url('../images/clouds.jpg'), url('../images/page-about.jpg');
      background-position: 4px 0, 4px 177px;
      background-repeat: no-repeat, no-repeat;
    }
  }
}
