.content-wrap-master {
  position: relative;

  &::before {
    position: absolute;
    z-index: 1;
  }

  &::after {
    position: absolute;
    z-index: 0;
  }
}

.content-wrap-outer-shadows {

  &::before {
    position: absolute;
  }

  &::after {
    position: absolute;
  }
}

.content-wrap-outer {
  position: relative;
  z-index: 12;
}

.content-wrap-inner-shadows {
  position: relative;
  z-index: 13;
}


.content-body {
  position: relative;
}

.content-header {
  position: relative;
}

.page-content {
  position: relative;
}

.touch-header {
  position: fixed;
  z-index: 100;

  @include respond-to(911px) {
    position: relative;
    z-index: inital;
  }
}

.login-active .touch-header {
  position: absolute;

  @include respond-to(911px) {
    position: relative;
    z-index: inital;
  }
}

.touch-menu {
  position: absolute;
  z-index: 9000;
}
