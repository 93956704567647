// Sizes
$short: rem(480px);
$tall: rem(640px);
$grande: rem(768px);
$venti: rem(1024px);
$trenta: rem(1280px);
$supremo: rem(1440px);
$maximo: rem(1920px);

// Fonts
$font-awesome: 'FontAwesome';
$rokkitt: 'Rokkitt', serif;
$verdana: verdana, arial, sans-serif;
$yanone-kaffeesatz: 'Yanone Kaffeesatz', sans-serif;

// Shadows

// Grayscale
$white: #fff;
$black: #000;

$alabaster: #f9f9f9;
$mischka: #d3d3d8;
$mid-gray: #66666f;
$dove-gray: #666565;
$abbey: #4b4b4c;


// Cream
$varden: #fff7e0;
$champagne: #faedd0;
$white-linen: #f9f4ea;
$albescent-white: #f7ecd6;
$janna: #f4ecd6;
$double-spanish-white: #e9dcc0;
$stark-white: #e8dcc1;
$sam: #e6dec7;
$bone: #e5dbc5;
$soft-amber: #d6cdb8;
$teak: #d5cab1;
$akaroa: #d4c6aa;
$yuma: #d3c19c;
$vanilla: #d1c3a8;
$chino: #cdc0a9;
$sand: #cbc2ae;
$coral-reef: #cbbda2;
$corkl: #c9bca1;
$bison-hide: #c7bfab;
$heathered-brown: #beb298;
$heathered-gray: #b5a992;
$hillary: #ad9f84;

// Brown
$makara: #8b7f67;
$pablo: #7d7667;
$coffee: #736955;
$wood: #716f64;
$pine-cone: #705f4f;
$piney: #62554d;
$kabul: #584639;
$milk-chocolate: #514135;
$mondo: #44362b;
$masskara: #423b37;
$merlin: #3c342f;
$birch: #3b2f26;
$ash: #362b23;
$cedar: #3b2616;
$coffee-bean: #29180f;

// Red
$red: #de0000;

// Orange
$ecstacy: #f68214;
$hot-cinnamon: #c56d19;
$hawaiian-tan: #9b5614;

// FPO
$fpo-gray: rgba(0, 0, 0, 0.125);
$fpo-gray2: rgba(127,127,127, 0.85);
