.page-content {
  width: 100%;
  padding-top: 50px;
  // padding-top: 0;
  background: $bone;

  @include respond-to(911px) {
    padding: 0 4px 4px;
    background-color: $albescent-white;
    background-image: url('../images/page.jpg');
    background-position: 4px 0;
    background-repeat: no-repeat;
  }

  .container {

    @include respond-to(911px) {
      padding: 69px 0 0;
    }

    &::before {

      @include respond-to(911px) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        pointer-events: none;
        content: '';
      }
    }

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: 0;
        z-index: 1;
        display: block;
        width: 903px;
        height: 24px;
        background: url('../images/main-horizontal-shadow.png') no-repeat bottom;
        content: '';
      }
    }
  }

  // CONTENT STYLING
  h2 {
    padding: 0;
    margin: 0;
    font-family: $verdana;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
  }

  h3 {
    padding: 0;
    margin: 0 0 10px;
    font-family: $yanone-kaffeesatz;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    font-style: normal;
    font-weight: normal;
    color: $hawaiian-tan;
    text-decoration: none;
  }

  ul {
    padding: 0 0 0 20px;
    margin: 5px 0 20px;
    font-size: 14px;

    li {
      list-style: square;
    }
  }

  p {

    @include respond-to(911px) {
      font-size: 15px;
      font-style: italic;
      line-height: 22px;
      color: $coffee-bean;
    }

    &.small {
      font-size: 10px;
      font-style: normal;
      color: $coffee-bean;
    }
  }

  .pad300 {

    @include respond-to(911px) {
      padding-right: 300px !important;
    }
  }

  .pad320 {

    @include respond-to(911px) {
      padding-right: 320px !important;
    }
  }

  .pad340 {

    @include respond-to(911px) {
      padding-right: 340px !important;
    }
  }

  .pad360 {

    @include respond-to(911px) {
      padding-right: 360px !important;
    }
  }

  .pad380 {

    @include respond-to(911px) {
      padding-right: 380px !important;
    }
  }

  .hint {
    display: block;
    padding: 0 17px;
    font-size: 13px;
    font-style: normal;
    letter-spacing: 1px;
    color: $hawaiian-tan;
    text-transform: uppercase;
  }
}


.page-primary {

  .page-content {

    .container {

      @include respond-to(911px) {
        padding-top: 117px;
      }
    }
  }
}

.page-secondary {

  .page-content {

    .container {

      @include respond-to(911px) {
        padding-top: 117px;
      }
    }
  }
}

.page-content {

  .container {

    &::before {

      @include respond-to(911px) {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.page-noshadow {

  .page-content {

    .container {

      &::before {

        @include respond-to(911px) {
          box-shadow: none;
        }
      }
    }
  }
}
