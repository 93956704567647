.form-row {
  display: block;
  overflow: hidden;
}

.form-message {
  display: block;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  background: $white-linen;
  border: 1px solid $heathered-gray;
}

.form-container {

  + .form-container {
    margin-top: 20px;
  }

  .form-row {
    display: block;
    width: 100%;
    overflow: hidden;

    @include respond-to(600px) {
      padding: 0 0 20px;
    }

    &:last-child {

      @include respond-to(600px) {
        padding-bottom: 0;
      }
    }
  }

  .form-actions {
    display: block;
    width: 100%;
    padding: 20px 0 0;
    overflow: hidden;
    border-bottom: 0 !important;
  }

  .form-group {
    display: block;
    width: 100%;
    padding: 0 0 10px;
    overflow: hidden;

    @include respond-to(600px) {
      float: left;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.first {

      @include respond-to(600px) {
        padding-left: 0;
      }
    }

    &.last {

      @include respond-to(600px) {
        padding-right: 0;
      }
    }

    &.span-1 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 1);
      }
    }

    &.span-2 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 2);
      }
    }

    &.span-3 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 3);
      }
    }

    &.span-4 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 4);
      }
    }

    &.span-5 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 5);
      }
    }

    &.span-6 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 6);
      }
    }


    &.span-7 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 7);
      }
    }


    &.span-8 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 8);
      }
    }


    &.span-9 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 9);
      }
    }


    &.span-10 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 10);
      }
    }


    &.span-11 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 11);
      }
    }


    &.span-12 {

      @include respond-to(600px) {
        width: calc((100% / 12) * 12);
      }
    }

    + .form-group {

      @include respond-to(600px) {
        padding-left: 10px;
      }
    }

    label {
      display: block;
      font-style: normal;
      font-weight: bold;

      small {
        display: block;
        font-size: 12px;
        float: right;
      }

      .validation-message {
        float: right;
        font-weight: normal;
        color: $red;
      }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      display: block;
      float: left;
      width: 100%;
      height: 37px;
      padding: 0 10px;
      font-size: 14px;
      font-style: normal;
      color: inherit;
      border-color: $heathered-gray;
      border-style: solid;
      border-width: 1px;
    }

    select {
      display: block;
      float: left;
      width: 100%;
      height: 37px;
      padding: 0 10px;
      font-size: 14px;
      font-style: normal;
      color: inherit;
      border-color: $heathered-gray;
      border-style: solid;
      border-width: 1px;

      &[multiple="multiple"] {
        min-height: 118px;
        padding: 10px;
      }
    }

    textarea {
      display: block;
      float: left;
      width: 100%;
      min-height: 118px;
      padding: 0 10px;
      font-size: 14px;
      font-style: normal;
      color: inherit;
      border-color: $heathered-gray;
      border-style: solid;
      border-width: 1px;
    }

    .checkbox-list-container {
      display: block;
      height: 118px;
      padding: 7px 10px;
      overflow-y: scroll;
      background: $white;
      border: 1px solid $heathered-gray;
    }

    .checkbox-list {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: block;
        padding: 5px 0;
        overflow: hidden;
        list-style: none;

        input {
          float: left;
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        label {
          float: left;
          font-weight: normal;
          line-height: 16px;
        }
      }
    }

    .form-checkbox {
      display: block;
      margin-bottom: 10px;
      overflow: hidden;

      input {
        float: left;
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      label {
        float: left;
        font-weight: normal;
        line-height: 16px;
      }

      &.special label {
        font-weight: bold;
      }
    }


    .radio-list {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: block;
        padding: 5px 0;
        overflow: hidden;
        list-style: none;

        input {
          float: left;
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        label {
          float: left;
          font-weight: normal;
          line-height: 16px;
        }
      }

      &.radio-list-horizontal {
        overflow: hidden;

        li {
          float: left;
          width: auto;
          margin-right: 20px;
        }
      }
    }
  }
}
