.content-wrap-inner {
  // padding-top: 50px;

  @include respond-to(911px) {
    width: 911px;
    margin: 0 auto;
  }
}

.content-wrap-inner-shadows {

  @include respond-to(919px) {
    width: 919px;
    padding: 0 4px;
    margin: 0 auto;
  }
}

.content-wrap-outer {
  width: 100%;
  padding: 0;

  @include respond-to(911px) {
    padding-bottom: 31px;
    background-color: $piney;
    background-image: url('../images/shadow-01-bottom.png'), url('../images/shadow-01-repeat.png'), url('../images/top-bars.png');
    background-position: 50% 100%, 50% 0, 0 0;
    background-repeat: no-repeat, repeat-y, repeat-x;
  }

  @include respond-to(973px) {
    width: 973px;
    margin: 0 auto;
  }
}

.content-wrap-outer-shadows {

  @include respond-to(981px) {
    width: 981px;
    padding: 0 4px;
    margin: 0 auto;
  }
}

.content-wrap-master {
  width: 100%;
  background-color: $kabul;
  background-image: url('../images/shadow-00-bottom.png'), url('../images/shadow-00-repeat.png');
  background-position: 50% 100%, 50% 0;
  background-repeat: no-repeat, repeat-y;

  &::before {
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 17px;
    background: #656565;
    content: '';
  }

  &::after {
    position: absolute;
    top: 17px;
    right: 0;
    left: 0;
    display: block;
    height: 59px;
    background: $makara;
    border-bottom: 1px solid $masskara;
    content: '';
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
  }
}
