.page-physician {

  .page-content {

    @include respond-to(911px) {
      background-image: url('../images/clouds.jpg'), url('../images/page-physician.jpg');
      background-position: 4px 0, 4px 177px;
      background-repeat: no-repeat, no-repeat;
    }
  }

  .search-panel {

    @include respond-to(911px) {
      width: 400px;
    }
  }

  .form-row {
    display: block;

    + .form-row {
      margin: 15px 0 0;
    }
  }

  input[type="text"] {
    display: block;
    width: 100%;
    height: 37px;
    padding: 0 6px;
    font-size: 14px;
    font-style: normal;
    color: inherit;
    border: 1px solid $kabul;
  }

  select {
    display: block;
    height: 37px;
    padding: 0 6px;
    font-size: 14px;
    font-style: normal;
    color: inherit;
    border: 1px solid $kabul;
  }

  label {
    font-weight: bold;
  }

  .radio-list {
    padding: 0;
    margin: 4px 0 0;
    list-style: none;
  }

  .radio-list li {
    display: block;
    float: left;
    width: auto;
    padding: 6px 4px;
    margin: 0;
    overflow: hidden;
    list-style: none;
  }

  .radio-list li input[type="radio"] {
    float: left;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .radio-list li label {
    display: block;
    float: left;
    width: calc(100% - 26px);
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    cursor: pointer;
  }

  .results-container {
    position: relative;
    left: -50px;
    z-index: 22;
    width: calc(100% + 100px);
    padding: 50px;
    margin-top: 15px;
    background: rgba(244, 236, 214, 0.8);
  }
}
