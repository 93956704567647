html,
body,
form {

  @include respond-to(911px) {
    height: 100%;
    min-height: 100%;
  }
}

.page {

  @include respond-to(911px) {
    min-height: 100%;
    // transition: all 0.2s ease-out;

    @include display(flex);
    @include flex-direction(column);
  }
}

.login-active .page {

  @include respond-to(911px) {
    min-height: calc(100% - 112px);
  }
}

.content-wrap-master {

  @include respond-to(911px) {
    @include flex(none);
  }
}

.footer-wrap {

  @include respond-to(911px) {
    min-height: 100%;

    @include flex(1);

    @include display(flex);
    @include flex-direction(column);
  }
}

.page-footer {

  @include respond-to(911px) {
    min-height: 100%;

    @include flex(1);

    @include display(flex);
    @include flex-direction(column);
  }

  .container {

    @include respond-to(911px) {
      height: 100%;

      @include flex(1);
    }
  }
}
