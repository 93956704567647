/*!
* CREATE Cancer Care - 2016 Responsive refresh.
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'variables';
@import 'mixins';

// Foundation, Normalize
@import 'vendor/foundation/foundation/settings';
@import 'vendor/foundation/normalize';
@import 'vendor/foundation/foundation';

// Bourbon
@import 'vendor/bourbon/4.2.4/bourbon';

// Layout
@import 'layout/flexbox';
@import 'layout/layers';
@import 'layout/base';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/content-wrap';

// Pages
@import 'pages/about';
@import 'pages/admin';
@import 'pages/home';
@import 'pages/research';
@import 'pages/protocol-search';
@import 'pages/protocol-detail';
@import 'pages/physician-search';
@import 'pages/documents';

// Components
@import 'components/base';
@import 'components/accordions';
@import 'components/buttons';
@import 'components/copyright';
@import 'components/content-body';
@import 'components/content-header';
@import 'components/footer-content';
@import 'components/forms';
@import 'components/google-map';
@import 'components/hero-images';
@import 'components/locations';
@import 'components/login-bar';
@import 'components/logo';
@import 'components/nav-admin';
@import 'components/nav-global';
@import 'components/nav-primary';
@import 'components/page-content';
@import 'components/sponsors';
@import 'components/tables';
@import 'components/tabs';
@import 'components/touch-header';
@import 'components/touch-menu';
@import 'components/welcome-bar';

// Modules

// Vendor
@import 'vendor/font-awesome/4.5.0/font-awesome';

// Print Styles

// Extras
@import 'misc';
@import 'shame';
