.content-body {
  display: block;
  padding: 25px 30px;
  font-family: $verdana;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  color: $coffee-bean;

  @include respond-to(911px) {
    min-height: 395px;
    padding: 39px 50px;
  }
}
