.page-home {

  .page-content {

    @include respond-to(911px) {
      background-color: $sand;
      background-image: url('../images/clouds.jpg'), url('../images/page-home.jpg');
      background-position: 4px 0, 4px 177px;
      background-repeat: no-repeat, no-repeat;
    }
  }
}
