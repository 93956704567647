.touch-menu {
  // top: -50px;
  right: 0;
  left: 0;
  z-index: 9000;
  display: none;
  width: 100%;
  height: auto;
  min-width: 320px;
  background: $cedar;

  @include respond-to(911px) {
    position: relative;
    z-index: 0;
    display: block;
    background: none;

  }

  &.active {
    display: block;
  }
}

.touch-menu-open {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 60px;
  height: 50px;
  font-size: 26px;
  line-height: 50px;
  color: $kabul;
  text-align: center;
  text-decoration: underline;
  transition: color 0.2s ease-out;

  &:hover {
    color: $ecstacy;
  }

  @include respond-to(911px) {
    display: none;
  }
}


.touch-menu-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 60px;
  height: 50px;
  font-size: 26px;
  line-height: 50px;
  color: $ecstacy;
  text-align: center;
  text-decoration: underline;
  transition: color 0.2s ease-out;

  &:hover {
    color: $white;
  }

  @include respond-to(911px) {
    display: none;
  }
}
