.page-content {

  .tabs {
    display: block;
    padding: 0;
    margin: 0 0 40px;
    overflow: hidden;
    list-style: none;

    li {
      display: block;
      float: left;
      padding: 0;
      margin: 0 10px 10px  0;
      list-style: none;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      position: relative;
      display: block;
      width: 80px;
      height: 80px;
      padding: 8px;
      font-size: 11px;
      font-weight: 700;
      line-height: 12px;
      color: $yuma;
      text-align: center;
      text-transform: uppercase;
      background: $kabul;

      &.active {

        &::after {
          position: absolute;
          bottom: -8px;
          left: 0;
          display: block;
          width: 100%;
          height: 8px;
          background: $ecstacy;
          content: '';
        }
      }
    }

    .icon {
      display: block;
      font-size: 36px;
      color: $white;
    }
  }

  .tab-content {

    p {
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      color: $black;

      strong,
      b {
        color: $ecstacy;
      }
    }
  }

  a.tab-substudies {
    padding-top: 8px;

    .icon {
      margin-bottom: 2px;
    }
  }

  a.tab-criteria {
    padding-top: 12px;

    .icon {
      margin-bottom: 10px;
    }
  }

  a.tab-docs {
    padding-top: 12px;

    .icon {
      margin-bottom: 10px;
    }
  }

  a.tab-notes {
    padding-top: 12px;

    .icon {
      margin-bottom: 10px;
    }
  }

  a.tab-type {
    padding-top: 12px;

    span {
      display: block;

      + span {
        padding-top: 8px;
      }
    }
  }

  .alt-tabs {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 40px;
    list-style: none;

    li {
      display: block;
      float: left;
      width: calc(100% / 8);
      padding: 0;
      margin: 0;
      list-style: none;

      &:nth-of-type(even) {

        a {
          background: #8b7f67;
        }
      }
    }

    a {
      position: relative;
      display: block;
      padding: 8px 4px;
      font-size: 9px;
      font-weight: 400;
      line-height: 12px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      background: $kabul;

      @include respond-to(430px) {
        padding-right: 8px;
        padding-left: 8px;
        font-size: 12px;
        font-weight: 700;
      }

      @include respond-to(768px) {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
      }

      &.active {

        &::after {
          position: absolute;
          bottom: -8px;
          left: 0;
          display: block;
          width: 100%;
          height: 8px;
          background: $ecstacy;
          content: '';
        }
      }
    }
  }

  .alt-tab-content {
    display: none;

    &.active {
      display: block;
    }

    ul.checkbox-list {
      padding: 0;
      margin: 0;

      li {
        display: block;
        margin: 0;
        padding: 0 0 12px;
        overflow: hidden;
        list-style: none !important;

        @include respond-to(680px) {
          width: 50%;
          float: left;
        }

        &:nth-of-type(even) {

          @include respond-to(680px) {
            float: left;
          }
        }

        &:nth-of-type(odd) {

          @include respond-to(680px) {
            clear: left;
            padding-right: 20px;
          }
        }

        input {
          float: left;
          margin-top: 1px;
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }

        label {
          float: left;
          width: calc(100% - 20px);
          font-style: normal;
          line-height: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
