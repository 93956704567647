.page-content {

  ul.locations {
    display: block;
    padding: 0;
    margin: 10px 0 20px;
    overflow: hidden;

    li {
      float: left;
      width: 220px;
      font-style: normal;
      list-style: none;
    }
  }

  p.locations {

    @include respond-to(911px) {
      float: left;
      width: 350px;
      padding-right: 50px;
    }

    &:nth-of-type(even) {

      @include respond-to(911px) {
        padding-right: 0;
      }
    }

    &:nth-of-type(odd) {

      @include respond-to(911px) {
        clear: left;
      }
    }
  }
}
