// modern clearfix
.group::after {
  display: table;
  clear: both;
  content: '';
}

.attention {
  background: cyan;
}
