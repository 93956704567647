.page-search {

  .page-content {

    @include respond-to(911px) {
      background-image: url('../images/page-search.jpg'), url('../images/page.jpg');
      background-position: 627px 177px, 4px 0;
      background-repeat: no-repeat, no-repeat;
    }

    .accordions {

      @include respond-to(911px) {
        width: 516px;
      }
    }

    input[type="text"] {
      display: block;
      height: 37px;
      padding: 0 20px;
      font-size: 14px;
      font-style: normal;
      color: inherit;
      border: 1px solid $kabul;
    }

    select {
      display: block;
      height: 37px;
      padding: 0 20px;
      font-size: 14px;
      font-style: normal;
      color: inherit;
      border: 1px solid $kabul;
    }

    button,
    input[type="submit"] {
      display: block;
      height: 37px;
      min-width: 86px;
      font-size: 14px;
      font-style: normal;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      background: $kabul;
      border: 0;

      &:hover {
        background-color: $ecstacy;
      }
    }

    .checkbox-list-container {
      max-height: 200px;
      padding: 5px 20px;
      overflow-y: scroll;
      background: $white;
      border: 1px solid $kabul;

      .checkbox-list {
        padding: 0;
        margin: 4px 0 0;
        list-style: none;
      }

      .checkbox-list li {
        display: block;
        padding: 6px 4px;
        margin: 0;
        overflow: hidden;
        list-style: none;
      }

      .checkbox-list li input[type="checkbox"] {
        float: left;
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      .checkbox-list li label {
        display: block;
        float: left;
        width: calc(100% - 26px);
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .form-row {

      + .form-row,
      + .form-submit {
        margin-top: 28px;
      }
    }

    .form-submit {
      overflow: hidden;

      input[type="submit"],
      button {
        float: right;
      }
    }

    .search-keyword {

      .keyword-search-box {
        float: left;
        width: calc(100% - 96px);
        padding-left: 40px;
        background-image: url('../images/search-icon.gif');
        background-position: 9px 8px;
        background-repeat: no-repeat;
      }

      .keyword-search-button {
        float: left;
        width: 86px;
        margin-left: 10px;
      }
    }

    .search-results {
      margin-top: 36px;

      h2 {
        font-family: $rokkitt;
        font-size: 36px;
        font-weight: 800;
        color: $kabul;
        text-transform: uppercase;
      }
    }

    .search-terms {
      padding: 17px 17px 0;

      @include respond-to(911px) {
        width: 516px;
      }

      h3 {
        margin-bottom: 0;
        font-family: $verdana;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
      }

      p {
        margin-top: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        color: $hot-cinnamon;
      }
    }

    .search-filters {
      padding: 0 17px 17px;

      @include respond-to(911px) {
        width: 516px;
      }

      h3 {
        font-family: $verdana;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
      }

      .checkbox-list {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          display: block;
          margin-bottom: 3px;
          overflow: hidden;
          list-style: none;
        }

        input {
          display: block;
          float: left;
          width: 16px;
          height: 16px;
          margin-top: 3px;
          margin-right: 10px;
        }

        label {
          display: block;
          float: left;
          font-size: 14px;
          font-style: normal;
        }
      }

      .checkbox {
        display: block;
        margin-bottom: 8px;

        input {
          display: block;
          float: left;
          width: 16px;
          height: 16px;
          margin-top: 3px;
          margin-right: 10px;
        }

        label {
          display: block;
          float: left;
          font-size: 14px;
          font-style: normal;
        }
      }
    }

    .table-protocol-search-results {
      margin-top: 40px;

      td {

        i {
          display: block;
          font-size: 24px;
        }

        span {
          display: block;
          font-size: 11px;
          font-weight: 700;
          line-height: 12px;
          color: $makara;
          text-transform: uppercase;
        }

        a {
          display: block;
          font-size: 11px;
          font-weight: 700;
          line-height: 14px;
        }
      }

      .col-substudies {
        max-width: 75px;
        text-align: center;

        i {
          margin-bottom: 4px;
          font-size: 28px;
        }

        a {
          color: $kabul;
        }
      }

      .col-notes {
        max-width: 75px;
        text-align: center;

        i {
          margin-bottom: 4px;
        }

        a {
          color: $kabul;
        }
      }

      .col-view {
        max-width: 125px;

        a {
          font-weight: 700;
          color: $hot-cinnamon;
          text-decoration: underline;
        }
      }
    }

    .no-results {
      display: block;
      padding: 16px;
      margin: 16px 0;
      font-weight: bold;
      background: $champagne;
      border: 1px solid $kabul;


      @include respond-to(911px) {
        width: 516px;
      }
    }
  }
}
