.page-content {

  h2.protocol-title {
    font-size: 18px;
    font-style: normal;
  }

  .protocol-stats {
    margin-top: 18px;
    margin-bottom: 24px;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;

    p {
      margin: 0;
      font-style: inherit;
      font-size: inherit;
    }

    b {
      font-weight: bold;
    }

    span {
      font-weight: 400;
    }
  }
}


.table-protocol-documents {

  .col-document-title {
    min-width: 25%;
  }

  td.col-document-type {
    font-size: 12px;
    line-height: 16px;
  }

  td.col-document-version {
    font-size: 12px;
    line-height: 16px;
  }

  td.col-document-approval {
    font-size: 12px;
    line-height: 16px;
  }

  td.col-document-link {
    font-size: 12px;
    line-height: 16px;

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
