.content-header {
  display: table;
  width: 100%;
  min-height: 114px;
  padding: 0 60px 0 30px;
  color: $varden;
  background: $pablo url('../images/clouds.jpg') no-repeat 50% 0;
  border-bottom: 18px solid $ecstacy;

  @include respond-to(911px) {
    display: block;
    min-height: auto;
    padding: 0;
    margin: 0;
    background: $sam;
    background: linear-gradient(to right, $hillary 0%, $sam 40%, $sam 60%, $chino 100%);
    border-bottom: 0;
  }

  &::before {

    @include respond-to(911px) {
      position: absolute;
      top: -6px;
      z-index: 0;
      display: block;
      width: 903px;
      height: 6px;
      background: url('../images/main-horizontal-shadow.png') no-repeat top;
      content: '';
    }
  }

  &::after {

    @include respond-to(911px) {
      position: absolute;
      bottom: -24px;
      z-index: 0;
      display: block;
      width: 903px;
      height: 24px;
      background: url('../images/main-horizontal-shadow.png') no-repeat bottom;
      content: '';
    }
  }

  h1 {
    display: table-cell;
    font-family: $rokkitt;
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: inherit;
    vertical-align: middle;

    @include respond-to(911px) {
      display: block;
      height: 60px;
      padding: 5px 49px 0;
      margin: 0;
      font-family: $yanone-kaffeesatz;
      font-size: 46px;
      font-weight: 400;
      line-height: 46px;
      color: $hawaiian-tan;
      text-shadow: 0 1px 1px $white, -1px 0 1px rgba(0, 0, 0, 0.25);
    }
  }
}

.page-secondary {

  .content-header {

    @include respond-to(911px) {
      background: $abbey;
    }

    &::after {

      @include respond-to(911px) {
        display: none;
      }
    }

    h1 {
      @include respond-to(911px) {
        height: 48px;
        padding: 0 49px;
        font-size: 25px;
        font-weight: 300;
        color: $mischka;
        text-shadow: none;
        text-transform: uppercase;
        border-top: 1px solid $mid-gray;
      }
    }
  }
}
