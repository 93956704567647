.touch-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 50px;
  background: $janna;
  transition: box-shadow 0.2s ease-out;

  @include respond-to(911px) {
    position: relative;
    width: auto;
    height: auto;
    pointer-events: none;
    background: none;
  }
}

.scrolled .touch-header {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.65);

  @include respond-to(911px) {
    box-shadow: 0;
  }
}

.menu-active .touch-header {
  display: none;

  @include respond-to(911px) {
    display: block;
  }
}
