.nav-primary {
  position: relative;
  display: block;

  @include respond-to(911px) {
    overflow: hidden;
    background: $heathered-gray;
    background: linear-gradient(to right, $heathered-gray 0%, $coral-reef 15%, $akaroa 50%, $heathered-brown 85%, $heathered-gray 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$heathered-gray', endColorstr='$heathered-gray', GradientType=1);
  }

  .ul1 {
    display: block;
    padding: 21px 0;
    margin: 0;
    list-style: none;

    @include respond-to(911px) {
      padding: 0;
    }
  }

  .li1 {
    display: block;

    @include respond-to(911px) {
      position: relative;
      float: left;
    }

    &::before {

      @include respond-to(911px) {
        position: absolute;
        top: 18px;
        left: 0;
        display: block;
        width: 1px;
        height: 15px;
        background: $pine-cone;
        content: '';
      }
    }

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: 18px;
        right: 0;
        display: block;
        width: 1px;
        height: 15px;
        background: $kabul;
        content: '';
      }
    }

    &:first-child::before,
    &:last-child::after {

      @include respond-to(911px) {
        display: none;
      }
    }
  }

  .a1 {
    display: block;
    padding: 8px 17px;
    font-family: $rokkitt;
    font-size: 28px;
    font-weight: 700;
    color: $ecstacy;
    text-align: right;

    @include respond-to(911px) {
      padding: 13px 15px;
      font-size: 18px;
      line-height: 18px;
      color: $kabul;
      text-align: center;
    }

    &:hover {

      @include respond-to(911px) {
        color: $ecstacy;
      }

      span {

        @include respond-to(911px) {
          background: $milk-chocolate;
          border-color: $black;
        }
      }
    }

    span {

      @include respond-to(911px) {
        display: block;
        padding: 3px 6px 1px;
        background: transparent;
        border: 1px solid transparent;
      }
    }
  }
}


.nav-wrap {

  @include respond-to(911px) {
    position: relative;
    left: 50%;
    display: block;
    float: left;
  }

  .nav-wrap-inner {

    @include respond-to(911px) {
      position: relative;
      right: 50%;
      float: left;
    }
  }
}
