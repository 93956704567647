.page-content {

  .documents-list-grid {
    font-size: 12px !important;

    th {
      display: none;
    }


    p {
      font-size: inherit;
    }

    .document-container {
      padding: 20px 0;
      border-bottom: 1px solid #9a9a9a;
    }

    tr:last-child .document-container {
      border-bottom: none;
    }

    .doc-actions {
      margin-bottom: 0;
    }
  }
}