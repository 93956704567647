// Base transition mixin
@mixin transition($property: all, $duration: 0.2s, $ease: ease) {
  transition: $property $duration $ease;
}

// default resposive mixin
@mixin respond-to($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

// Responsiveness happens here.
// @include respond(min, 320px) { CONTENT }
// @include respond(max, lg: 640px) { CONTENT }
// @include respond(minmax, 320px, 640px) { CONTENT }

@mixin respond($size, $sm: "", $lg: "") {
  @if $size == "min" {
    @media only screen and (min-width: $min) {
      @content;
    }
  }

  @if $size == "max" {
    @media only screen and (max-width: $lg) {
      @content;
    }
  }

  @if $size == "minmax" {
    @media only screen and (min-width: $sm) and (max-width: $lg) {
      @content;
    }
  }
}

// @include font-size(1,1);
//// font-size: 16px; line-height: 16px; font-size: 1rem; line-height: 1rem;

@mixin font-size($sizeValue: 1, $line: $sizeValue * 1.5) {
  font-size: $sizeValue + px;
  line-height: $line + px;
  font-size: $sizeValue / 16 + rem;
  line-height: $line / 16 + rem;
}
