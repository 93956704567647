.map-canvas {
  width: 100%;
  height: 337px;
  margin-bottom: 10px;
  border: 1px solid $heathered-gray;

  @include respond-to(911px) {
    width: 400px;
  }
}
