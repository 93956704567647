html {
  width: 100%;
  min-width: 320px;
  box-sizing: border-box;

  @include respond-to(911px) {
    min-width: 911px;
  }
}

body {
  width: 100%;
  min-width: 320px;

  @include respond-to(911px) {
    min-width: 911px;
  }
}

form {
  width: 100%;
  min-width: 320px;

  @include respond-to(911px) {
    min-width: 911px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
