.page-home {

  .content-body {

    &::after {

      @include respond-to(911px) {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 425px;
        height: 567px;
        background: url('../images/img-home-photo.png') no-repeat 0 0;
        content: '';
      }
    }
  }
}

.page-search {

  .content-body {

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: -174px;
        right: 0;
        display: block;
        width: 349px;
        height: 569px;
        background: url('../images/img-search-photo.png') no-repeat 0 0;
        content: '';
      }
    }
  }
}

.page-research {

  .content-body {

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: -155px;
        right: 5px;
        display: block;
        width: 361px;
        height: 550px;
        background: url('../images/img-research-photo.png') no-repeat 0 0;
        content: '';
      }
    }
  }
}

.page-physician {

  .content-body {

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: -160px;
        right: 0;
        display: block;
        width: 425px;
        height: 555px;
        background: url('../images/img-physician-photo.png') no-repeat 0 0;
        content: '';
      }
    }
  }
}

.page-about {

  .content-body {

    &::after {

      @include respond-to(911px) {
        position: absolute;
        top: -143px;
        right: 0;
        display: block;
        width: 348px;
        height: 538px;
        background: url('../images/img-about-photo.png') no-repeat 0 0;
        content: '';
      }
    }
  }
}
