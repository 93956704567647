.welcome-bar {
  position: relative;
  display: block;
  height: 50px;
  padding: 0 17px;
  line-height: 50px;
  color: $teak;
  background: $cedar;

  @include respond-to(911px) {
    position: absolute;
    top: -84px;
    right: 0;
    z-index: 3;
    height: auto;
    padding: 0 12px;
    font-size: 10px;
    line-height: 26px;
    color: $mischka;
    background: none;
  }

  .welcome-message {
    font-size: 14px;
    font-weight: 700;
    color: inherit;

    @include respond-to(911px) {
      font-size: 10px;
      font-weight: 400;
    }
  }
}
