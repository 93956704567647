.page-admin {

  .page-content {

    @include respond-to(911px) {
      background-image: url('../images/page.jpg');
      background-position: 4px 0;
      background-repeat: no-repeat;
    }
  }

  .form-container {

    @include respond-to(911px) {
      padding: 20px;
      background: $white-linen;
      border: 1px solid $heathered-gray;
    }
  }
}


.admin-header {
  width: 100%;

  .admin-search-box {
    display: block;
    float: left;
    width: calc(100% - 213px);
    height: 37px;
    padding: 0 10px;
    font-size: 14px;
    font-style: normal;
    color: inherit;
    border-color: $heathered-gray;
    border-style: solid;
    border-width: 1px 0 1px 1px;
  }
}

.admin-users-header {

  .admin-search-box {
    width: calc((100% - 216px) * 0.6);
  }

  .admin-search-dropdown-list {
    display: block;
    float: left;
    width: calc((100% - 216px) * 0.4);
    height: 37px;
    padding: 0 6px;
    font-size: 14px;
    font-style: normal;
    color: inherit;
    border-color: $heathered-gray;
    border-style: solid;
    border-width: 1px 0 1px 1px;
  }
}

.table-admin {
  margin-top: 20px;

  td {
    padding: 4px 8px !important;
    font-size: 11px !important;
  }

  .col-edit {
    width: 29px;
    padding: 0;

    a {
      display: block;
      width: 28px;
      padding: 8px;
      font-size: 14px;

      &:hover {
        color: $ecstacy;
      }
    }
  }
}


.form-container + .button-container {
  margin-top: 20px;
}

.button-container {
  margin-bottom: 20px;
}
