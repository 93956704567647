.table {
  width: 100%;
  border-color: $kabul;
  border-style: solid;
  border-width: 1px;

  tr {

    &:nth-of-type(even) {

      td {
        background: $white-linen;
      }
    }

    &:nth-of-type(odd) {

      td {
        background: $double-spanish-white;
      }
    }
  }

  th {
    padding: 4px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: $mondo;
    background: $heathered-gray;
    border-color: $kabul;
    border-style: solid;
    border-width: 0 0 1px;
  }

  td {
    padding: 16px;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: $coffee-bean;
    border-color: $kabul;
    border-style: solid;
    border-width: 0 0 1px;
  }

  .col-left {
    text-align: left;
  }

  .col-center {
    text-align: center;
  }

  .col-right {
    text-align: right;
  }
}


.table-alt {
  width: 100%;

  tr {

    &:nth-of-type(even) {

      td {
        background: $champagne;
      }
    }

    &:nth-of-type(odd) {

      td {
        background: $stark-white;
      }
    }
  }

  th {
    padding: 9px 16px 11px;
    color: $white;
    background: $kabul;
  }
}


.table-listing {
  border-color: $heathered-gray;

  th {
    padding: 8px;
    font-size: 13px;
    line-height: 13px;
    border-bottom: 0;
  }

  td {
    padding: 8px;
    font-size: 11px;
    line-height: 13px;
    border-bottom: 0;
  }
}
