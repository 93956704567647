.login-bar {
  position: relative;
  display: block;
  background: $merlin;
  // padding-top: 33px;

  @include respond-to(911px) {
    height: 112px;
    padding-top: 0;
    background: $birch;
  }

  .container {
    padding: 27px;
    margin: 0 auto;

    @include respond-to(911px) {
      width: 100%;
      max-width: 911px;
      padding-top: 0;
      padding-right: 38px;
      padding-left: 38px;
    }
  }

  .heading {
    display: block;
    font-family: $yanone-kaffeesatz;
    font-size: 30px;
    color: $ecstacy;
    margin-bottom: 10px;

    @include respond-to(911px) {
      float: left;
      padding: 0;
      margin: 0 25px 0 0;
    }
  }

  .login-form {

    @include respond-to(911px) {
      float: left;
    }
  }

  .login-username {
    margin-bottom: 14px;

    @include respond-to(911px) {
      float: left;
      padding: 15px 0;
      margin: 0 14px 0 0;
    }
  }

  .login-password {
    margin-bottom: 14px;

    @include respond-to(911px) {
      float: left;
      padding: 15px 0;
    }
  }

  .login-button {
    // padding: 15px 0;
    padding: 0 0 15px;

    @include respond-to(911px) {
      float: left;
      padding: 15px 0;
      margin: 0 10px;
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 11px;
    line-height: 12px;
    color: $vanilla;

    @include respond-to(911px) {
      float: left;
      height: 22px;
      padding: 5px 0;
      margin: 0 2px 0 0;

    }
  }

  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    padding: 4px;
    font-size: 14px;
    background: #fff;
    border-color: #727272 #b4b4b4 #dadada;
    border-style: solid;
    border-width: 1px;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.25);


    @include respond-to(911px) {
      float: left;
      width: 153px;
      height: 22px;
      font-size: 11px;
    }
  }

  input[type="submit"],
  button {
    display: block;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    font-style: normal;
    line-height: inherit !important;
    background: #fafafa;
    border: 0;
    border-radius: 12px;
  }

  .login-links {

    @include respond-to(911px) {
      padding: 12px 0;
    }

    a {
      margin: 0 12px 0 0;
      font-size: 11px;
      line-height: 12px;
      color: $corkl;
      text-decoration: underline;

      &:last-child {

        @include respond-to(911px) {
          margin-right: 0;
        }
      }
    }
  }
}
