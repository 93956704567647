.logo {
  position: absolute;
  top: 2px;
  left: 7px;
  display: block;
  width: 145px;
  height: 40px;
  text-indent: -10000em;
  background: url('../images/logo-CREATE-mobile.png') no-repeat 0 0;
  outline: none;

  @include respond-to(911px) {
    position: relative;
    top: auto;
    left: auto;
    display: block;
    width: 271px;
    height: 84px;
    padding: 0;
    margin: 0;
    pointer-events: all;
    background: url('../images/logo-CREATE-desktop.png') no-repeat 0 0;
  }

  &::after {

    @include respond-to(911px) {
      position: absolute;
      top: 0;
      right: -8px;
      display: block;
      width: 8px;
      height: 100%;
      background: url('../images/shadow-logo-right-desktop.png') no-repeat 0 0;
      content: '';
    }
  }
}
