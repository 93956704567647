.sponsors {

  ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: block;
      float: left;
      width: 100%;
      padding: 0;
      margin: 0 0 15px;
      text-align: center;
      list-style: none;

      @include respond-to(480px) {
        width: auto;
        margin: 0 15px 0 0;
        text-align: left;
      }

      a {
        display: inline-block;
      }

      img {
        display: block;
      }
    }
  }
}
