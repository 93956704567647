.page-content {

  .buttons {
    margin-top: 40px;
  }

  .button {
    display: block;
    float: left;
    width: auto;
    height: 37px;
    padding: 0 12px 2px;
    font-size: 12px;
    font-style: normal;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;

    + .button {
      margin-left: 20px;
    }
  }

  .button-primary {
    color: $white;
    background: $kabul;
    border: 1px solid $kabul;

    &:hover {
      background: $ecstacy;
      border-color: $white;
    }
  }

  .button-alternate {
    color: $kabul;
    background: $champagne;
    border: 1px solid $kabul;

    &:hover {
      color: $ecstacy;
      background: $white;
      border: 1px solid $ecstacy;
    }
  }

  .button-secondary {
    padding-right: 0;
    padding-left: 0;
    background: none;
    border: 1px solid transparent;

    &:hover {
      text-decoration: underline;
    }
  }


}

// button.button,
// input[type="submit"] {
//   padding: 0;
//   margin: 0;
//   font-style: normal;
//   line-height: inherit !important;
// }