.page-header {
  position: relative;
  display: block;
  width: 100%;

  .container {

    @include respond-to(911px) {
      background: $makara;
    }
  }
}
