.page-footer {
  width: 100%;
  padding: 20px 36px;
  margin: 0 auto;
  background-color: $merlin;
  background-image: url('../images/shadow-footer.png');
  background-position: top;
  background-repeat: repeat-x;

  @include respond-to(911px) {
    padding: 0;
    margin: 0;
    background-color: $ash;
  }

  .container {

    @include respond-to(911px) {
      max-width: 973px;
      padding: 20px 36px;
      background-color: $merlin;
      background-image: url('../images/shadow-footer.png');
      background-position: top;
      background-repeat: repeat-x;
    }
  }
}
